h1 {
	margin: auto;
}

h2 {
	font-size: 1.15em;
	font-weight: normal;
}

button {
	cursor: pointer;
	min-width: 100px;
	padding: 15px 20px;
	border: 0;
	font-size: 1em;

	& + button {
		margin-left: 25px;
	}

	&.yes {
		background: darkgreen;
		color: white;

		&:hover {
			background: #018701;
		}
	}

	&.no {
		background: darkred;
		color: white;

		&:hover {
			background: #933;
		}
	}

	&.restart {
		background: darkblue;
		color: white;

		&:hover {
			background: rgb(5, 5, 161);
		}
	}
}

.questions {
	position: absolute;
	top: 45%;
	left: 10%;
	width: 80%;
	transform: translateY(-50%);

	.question {
		.text {
			width: 80%;
			margin: auto;
			font-size: 1.25em;
		}
		
		.answers {
			margin-top: 1%;
		}
	}
}

@media all and (min-width: 640px) {
	h1 {
		width: 25%;
	}

	h2 {
		font-size: 1.5em;
	}
}