body {
  height: 100%;
  margin: 2%;
  background: #f6f6f6;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 125%;
  text-align: center;
}

*:focus {
  outline: 5px double black;
}